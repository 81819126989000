<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.duration.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.duration.contract-type') }}*</label>
                    <div v-if="validator.selectedContractType.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectedContractType.$error}">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="duration.selectedContractType != ''" @click.stop="duration.selectedContractType = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="duration.selectedContractType"
						v-bind:class="{populate: duration.selectedContractType != ''}"
						class="select-tags-tbf"
						:options="contractTypes"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.duration.contract-type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
			<div v-if="duration.selectedContractType.id == 2" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.duration.months') }}</label>
					<div v-if="validator.months.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.months.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="duration.months != ''" @click.stop="duration.months = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.duration.months-ph')" class="input-text" v-model="duration.months">
				</div>
			</div>
			<div v-if="duration.selectedContractType.id == 2" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.duration.final_date') }}</label>
					<div v-if="validator.final_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.final_date.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(duration.final_date).length" @click.stop="duration.final_date = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="duration.final_date"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(duration.final_date).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.duration.final_date-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.duration.contract_observations') }}</label>
				</div>
				<div class="input-box bg-white">
					<textarea rows="3" :placeholder="$t('salary.duration.contract_observations-ph')" v-model="duration.contract_observations"></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconContract from '../../../../Icons/Contract'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconContract,
			IconCalendar,
			IconClose
		},
		props: {
			duration: Object,
			validator: Object,
			contractTypes: Array
		},
		data() {
			return {
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		}
	}
</script>