<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.contract_details.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract_details.bank') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-bank /></div>
					<div class="icon-right" v-if="contract_details.selectedBank != ''" @click.stop="contract_details.selectedBank = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="contract_details.selectedBank"
						v-bind:class="{populate: contract_details.selectedBank != ''}"
						class="select-tags-tbf"
						:options="optionsBank"
						:allow-empty="true"
						:show-labels="false"
						track-by="id"
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.contract_details.bank-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract_details.iban_code') }}</label>
                    <div v-if="!validator.iban_code.minLength" class="error-msg">{{ $t('validator.required_iban')}}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: !validator.iban_code.minLength}">
					<div class="icon-left"><icon-card /></div>
					<div class="icon-right" v-if="contract_details.iban_code != ''" @click.stop="contract_details.iban_code = ''"><icon-close class="icon-clear" /></div>
					<input :maxlength="24" type="text"  :placeholder="$t('salary.contract_details.iban_code-ph')" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text" v-model="contract_details.iban_code">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract_details.health_insurance') }}*</label>
                    <div v-if="validator.selectedHealthInsurance.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectedHealthInsurance.$error}">
					<div class="icon-left"><icon-medical /></div>
					<div class="icon-right" v-if="contract_details.selectedHealthInsurance != ''" @click.stop="contract_details.selectedHealthInsurance = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="contract_details.selectedHealthInsurance"
						v-bind:class="{populate: contract_details.selectedHealthInsurance != ''}"
						class="select-tags-tbf"
						:options="optionsHealthInsurance"
						:allow-empty="true"
						:show-labels="false"
						track-by="id"
						label="label"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.contract_details.health_insurance-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract_details.cor_code') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-cor /></div>
					<div class="icon-right" v-if="contract_details.selectedCodeCor != ''" @click.stop="contract_details.selectedCodeCor = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="contract_details.selectedCodeCor"
						v-bind:class="{populate: contract_details.selectedCodeCor != ''}"
						class="select-tags-tbf"
						:options="optionsCodes"
						:allow-empty="true"
						:show-labels="false"
						track-by="code"
						label="name"
                        :internal-search="false"
                        @search-change="search"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.contract_details.cor_code-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconBank from '../../../../Icons/Bank'
	import IconCard from '../../../../Icons/Card'
	import IconMedical from '../../../../Icons/Medical'
	import IconCor from '../../../../Icons/Cor'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconBank,
			IconCard,
			IconMedical,
			IconCor,
			IconClose
		},
		props: {
			contract_details: Object,
			validator: Object,
            optionsBank: Array,
            optionsHealthInsurance: Array
		},
		data() {
			return {
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
                optionsCodes: [],
                filterCodes: []
			}
		},
		async mounted(){
            this.getCorCode()
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
        methods: {
            search (query) {
                this.optionsCodes = this.filterCodes.filter(e => e.code.toString().includes(query) || e.name.includes(query))
            },
            getCorCode(){
                axios.get(`/code_cors`).then((data) => {
                    this.optionsCodes = data.data.map(el => {
                        return {code: el.code, name:el.code + ' ' + el.name}
                    })
                    this.filterCodes = data.data.map(el => {
                        return {code: el.code, name:el.code + ' ' + el.name}
                    })
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
            }
        }
	}
</script>