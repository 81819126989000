<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'history'} })"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{$route.params.id ? $t('salary.contracts.edit-new-contract') :  $t('salary.contracts.create-modification-contract') }}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'history'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
            <show-contract v-if="userData" :data="userData" />
            <contract-modification v-if="!initial_contract" :modification="modification" :contractTypes="optionsModificationContract" :contractReason="optionsContractModificationReason" :months="months" :validator="$v.modification"/>
			<personal-data :personal_data="personal_data" :optionsCountries="optionsCountries" :optionsCounties="optionsCounties" :optionsTowns="optionsTowns" :optionsDocumentType="optionsDocumentType" :validator="$v.personal_data"/>
			<contract-details :contract_details="contract_details" :optionsBank="optionsBank" :optionsHealthInsurance="optionsHealthInsurance" :validator="$v.contract_details"/>
            <duration-contract :duration="duration" :contractTypes="contract_types_period" :validator="$v.duration"/>
            <salary-contract :salary="salary" :calculTypes="calcul_types" :validator="$v.salary"/>
            <work-program :work_program="work_program" :norms="norms" :normsType="norms_type" :validator="$v.work_program"/>
            <contract-fiscality :fiscality="fiscality" :incomeOptions="incomeOptions" :workConditionsOptions="workConditionsOptions" :taxExemptOptions="taxExemptOptions" :disabilityOptions="disabilityOptions" :invalidityOptions="invalidityOptions" :validator="$v.fiscality"/>

		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'history'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
    <loader-users-create v-else/>
</template>

<script>
	import IconArrowBack from '../../../Icons/ArrowLong'
	import IconClose from '../../../Icons/Close'
	import PersonalData from './FormComponents/PersonalData'
	import ContractDetails from './FormComponents/ContractDetails'
    import DurationContract from './FormComponents/Duration'
    import SalaryContract from './FormComponents/Salary'
    import WorkProgram from './FormComponents/WorkProgram'
    import ContractFiscality from './FormComponents/Fiscality'
    import ContractModification from './FormComponents/ModificationContract'
    import ShowContract from  './ContractDetails/ShowContract'
    import LoaderUsersCreate from '../../../PagesLoaders/UsersCreate'
	import { required, requiredIf, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

    const validCNP = (value) => !helpers.req(value) || checkCNP(value)

    function checkCNP( p_cnp ) {
        var i=0 , year=0 , hashResult=0 , cnp=[] , hashTable=[2,7,9,1,4,6,3,5,8,2,7,9];
        if( p_cnp.length !== 13 ) { return false; }
        for( i=0 ; i<13 ; i++ ) {
            cnp[i] = parseInt( p_cnp.charAt(i) , 10 );
            if( isNaN( cnp[i] ) ) { return false; }
            if( i < 12 ) { hashResult = hashResult + ( cnp[i] * hashTable[i] ); }
        }
        hashResult = hashResult % 11;
        if( hashResult === 10 ) { hashResult = 1; }
        year = (cnp[1]*10)+cnp[2];
        switch( cnp[0] ) {
            case 1  : case 2 : { year += 1900; } break;
            case 3  : case 4 : { year += 1800; } break;
            case 5  : case 6 : { year += 2000; } break;
            case 7  : case 8 : case 9 : { year += 2000; if( year > ( parseInt( new Date().getYear() , 10 ) - 14 ) ) { year -= 100; } } break;
            default : { return false; }
        }
        if( year < 1800 || year > 2099 ) { return false; }
        return ( cnp[12] === hashResult );
        
    }

	export default {
		components: {
			IconArrowBack,
			IconClose,
			PersonalData,
			ContractDetails,
            DurationContract,
            SalaryContract,
            WorkProgram,
            ContractFiscality,
            ContractModification,
            ShowContract,
            LoaderUsersCreate
		},
		data() {
			return {
                userData: {},
				loaded: false,
				error_message: '',
				contract: {},
				optionsCountries: [],
                optionsCounties: [],
                optionsTowns: [],
                optionsDocumentType: [],
                personal_data: {
                    cnp: '',
                    selectedCitizenship: '',
                    selectedCountry: '',
                    selectedCounty: '',
                    county: '',
                    selectedTown: '',
                    town: '',
                    street_and_number: '',
                    selectDocumentType: '',
                    series: '',
                    number: '',
                    issued_by: '',
                    issue_date: {}
                },
				optionsBank: [],
                optionsHealthInsurance: [],
                optionsCodes: [],
                filterCodes: [],
                contract_details: {
                    selectedBank: '',
                    iban_code: '',
                    selectedHealthInsurance: '',
                    selectedCodeCor: ''
                },
                duration: {
                    selectedContractType: '',
                    months: '',
                    final_date: {},
                    contract_observations: ''
                },
                contract_types_period: [],
                salary: {
                    calcul_type: {id: 1,name: "lunar"},
                    base_salary: '',
                    net_salary: '',
                    hourly_rate: '',
                    advance: ''
                },
                calcul_types: [],
                work_program: {
                    norm: 8,
                    norm_type: {id: 1 ,name: "ore/zi"},
                    daily_hours: '',
                    vacation_days: ''
                },
                norms: [],
                norms_type: [],
                fiscality: {
                    selectedIncomeType: '',
                    selectedWorkCondition: '',
                    basic_function: 1,
                    deduction_start_date: {},
                    tax_exempt: 0,
                    selectTaxExptionType: '',
                    retired: 0,
                    retirement_start_date: {},
                    selectDegreeOfDisability: '',
                    start_date_of_disability: {},
                    end_date_of_disability: {},
                    number_disability_document: '',
                    selectInvalidityType: '',
                    start_date_of_invalidity: {},
                    end_date_of_invalidity: {},
                },
                incomeOptions: [],
                workConditionsOptions: [],
                taxExemptOptions: [],
                disabilityOptions: [],
                invalidityOptions:[],
                modification: {
                    selectedModificationContract: '',
                    document_date: {},
                    active_from: '',
                    document_number: '',
                    selectedContractModificationReason: '',
                    observations: '',
                },
                optionsModificationContract: [],
                optionsContractModificationReason: [],
				months: [],
                initial_contract: 0
			}
		},
		validations: {
            modification: {
                selectedModificationContract: { 
                    required: requiredIf(function () {
                        return this.initial_contract == 0 
                    }) },
				active_from: { 
                    required: requiredIf(function () {
                        return this.initial_contract == 0 
                    }) },
            },
			personal_data: {
                cnp: { 
                    required: requiredIf(function () {
                        return !this.$route.params.id
                    }),
                    required, validCNP,
                    minLength: minLength(13),
                    maxLength: maxLength(13) 
                },
            },
			contract_details: {
                selectedHealthInsurance: { 
                    required
                },
                iban_code: {
                    minLength: minLength(24),
                    maxLength: maxLength(24) 
                }
            },
            duration: {
                selectedContractType: { required },
                months: { required: requiredIf(function () {
                    return this.duration.selectedContractType.id == 2
                }) },
                final_date:  { required: requiredIf(function () {
                    return this.duration.selectedContractType.id == 2
                }) },
            },
            salary: {
                base_salary: { required }
            },
            work_program: {
                norm: { required },
                norm_type: { required },
                daily_hours: { required }
            },
            fiscality: {
                selectedIncomeType: { required },
                selectedWorkCondition: { required },
                basic_function: { required },
                 deduction_start_date: {required: requiredIf(function () {
                    return this.fiscality.basic_function == 1
                }) },
                tax_exempt: { required },
                selectTaxExptionType: { required: requiredIf(function () {
                    return this.fiscality.tax_exempt == 1
                }) },
                retired: { required },
                retirement_start_date: { required: requiredIf(function () {
                    return this.fiscality.retired == 1
                }) },
                // start_date_of_disability: { required: requiredIf(function () {
                //     return this.fiscality.selectDegreeOfDisability
                // }) },
                // end_date_of_disability: { required: requiredIf(function () {
                //     return this.fiscality.selectDegreeOfDisability
                // }) },
                // number_disability_document: { required: requiredIf(function () {
                //     return this.fiscality.selectDegreeOfDisability
                // }) },
                // start_date_of_invalidity: { required: requiredIf(function () {
                //     return this.fiscality.selectInvalidityType
                // }) },
                // end_date_of_invalidity: { required: requiredIf(function () {
                //     return this.fiscality.selectInvalidityType
                // }) }
            },
		},
		async mounted(){
			await this.getCountries();
			await this.getCorCode();
			await this.getDateArray()
            await this.getSelectData()

			this.$root.$on('userDataSelect', () => {
                this.clearData()
                this.getUserData()
			})
            this.$root.$on('countySelect', (county_id) => {
                this.getTowns(county_id)
			})
			
            if(this.$route.params.id ){
				await this.getContractData()
			}
             if(!this.$route.params.id && this.$route.params.contract_id) {
				await this.getContractDataCreate()
			}
		},
		methods: {
			async getDateArray() {
                await axios.get(`${this.$auth.user().instance.id}/months`)
				.then(({data}) => {
                    this.months = data.data.map(el => {
						return {date: el.date, name: el.name, month: el.month, year: el.year, img: el.is_locked ? '/build/images/lock.png' : null}
					})
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountries = Object.entries(data.data).map((el,index) =>{
						return{id: el[0], name: el[1]}
					})
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
            async getTowns(county_id){
                this.personal_data.selectedTown = ''
                await axios.get(`/towns?county_id=${county_id}`).then((data) => {
                    this.optionsTowns = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
            },
			getCorCode(){
                axios.get(`/code_cors`).then((data) => {
                    this.optionsCodes = data.data.map(el => {
                        return {code: el.code, name:el.code + ' ' + el.name}
                    })
                    this.filterCodes = data.data.map(el => {
                        return {code: el.code, name:el.code + ' ' + el.name}
                    })
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
            },
            async getSelectData(){
                await axios.get(`${this.$auth.user().instance.id}/contract-form-noms`)
				.then(({data}) => {
                    this.contract_types_period = data.data.contract_period_types
                    this.calcul_types = data.data.contract_salary_calculation_types
                    this.norms = data.data.norms
                    this.norms_type = data.data.working_norm_types
                    this.incomeOptions = data.data.income_types
                    this.workConditionsOptions = data.data.work_condition_types
                    this.taxExemptOptions = data.data.tax_exemption_types
                    this.disabilityOptions = data.data.disability_degree_types
                    this.invalidityOptions = data.data.invalidity_degree_types
                    this.optionsModificationContract = data.data.contract_modification_types
                    this.optionsContractModificationReason = data.data.contract_modification_reasons
					this.optionsCounties = data.data.counties
                    this.optionsDocumentType = data.data.user_document_types
                    this.optionsBank = data.data.banks
                    this.optionsHealthInsurance = data.data.national_health_insurance_agencies
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
                    setTimeout(() => {
                        this.loaded = true
                        setTimeout(() => {
                            $('.opacity-page').addClass('show')
                        }, 0)
                    }, 0)
                })
            },
			clearData() {
                this.personal_data.cnp = ''
                this.personal_data.selectedCitizenship = ''
                this.personal_data.selectedCountry = ''
                this.personal_data.selectedCounty = ''
                this.personal_data.county = ''
                this.personal_data.selectedTown = ''
                this.personal_data.town = ''
                this.personal_data.street_and_number = ''
                this.personal_data.selectDocumentType = ''
                this.personal_data.series = ''
                this.personal_data.number = ''
                this.personal_data.issued_by = ''
                this.personal_data.issue_date = {}
            },
            async getContractData(){
                await axios.get(`/contract-modifications/${this.$route.params.id}/edit`).then(async ({data}) => {

                    this.initial_contract = data.data.initial_contract
                    if(!this.initial_contract) {
                        // modification
                        if(data.data.document_date){
                            this.modification.document_date = {
                                selectedDate: data.data.document_date,
                                multipleDateRange: [],
                                selectedDates: [],
                                selectedDateTime: false,
                                selectedDatesItem: "",
                                selectedHour: "00",
                                selectedMinute: "00",
                                dateRange: {end: '',start: ''}
                            }
                        }
                        this.modification.active_from = this.months.find(el => el.date === data.data.active_from)
                        this.modification.selectedModificationContract = this.optionsModificationContract.find(el => el.id == data.data.contract_modification_type.id)
                        this.modification.document_number = data.data.document_number ? data.data.document_number : ''
                        this.modification.selectedContractModificationReason = data.data.modification_reason ? this.optionsContractModificationReason.find(el => el.id == data.data.modification_reason.id) : ''
                    }

					// personal data
					if(data.data.issue_date){
                        this.personal_data.issue_date = {
                            selectedDate: data.data.issue_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    this.userData = {avatar: data.data.avatar ? data.data.avatar : '' , cnp: data.data.cnp ? data.data.cnp : '', name: data.data.first_name && data.data.last_name ? data.data.first_name + ' ' + data.data.last_name : ''}

                    this.personal_data.cnp = data.data.cnp
                    this.personal_data.selectedCitizenship = this.optionsCountries.find(el => el.name == data.data.country)
                    this.personal_data.selectedCountry = this.optionsCountries.find(el => el.name == data.data.country)
                    if(data.data.country == 'Romania') {
                        if(data.data.county) {
                            this.personal_data.selectedCounty = this.optionsCounties.find(el => el.name.toLowerCase() == data.data.county.toLowerCase())
                            await this.getTowns(this.personal_data.selectedCounty.id)
                        }
                        if(data.data.town) {
                            this.personal_data.selectedTown = this.optionsTowns.find(el => el.name == data.data.town)
                        }
                    } else {
                        this.personal_data.county = data.data.county
                        this.personal_data.town = data.data.town
                    }
                    this.personal_data.street_and_number = data.data.street_and_number
					if(data.data.user_document_type_id) {
						this.personal_data.selectDocumentType = this.optionsDocumentType.find(el => el.id == data.data.user_document_type_id.id)
					}
                    this.personal_data.series = data.data.series
                    this.personal_data.number = data.data.number
                    this.personal_data.issued_by = data.data.issued_by

					// contract details 
                    if(data.data.contract_details) {
                        this.contract_details.selectedBank = data.data.contract_details.bank_id ? this.optionsBank.find(el => el.id == data.data.contract_details.bank_id) : '';
                        this.contract_details.iban_code = data.data.contract_details.iban_code ? data.data.contract_details.iban_code : ''
                        this.contract_details.selectedHealthInsurance = data.data.contract_details.national_health_insurance_agency_id ? this.optionsHealthInsurance.find(el => el.id == data.data.contract_details.national_health_insurance_agency_id) : '';
                        this.contract_details.selectedCodeCor = data.data.contract_details.code_cor ? this.optionsCodes.find(el => el.code == data.data.contract_details.code_cor) : '';
                    }

                    // duration
                    this.duration.selectedContractType = data.data.contract_period.contract_period_type_id ? this.contract_types_period.find(el => el.id == data.data.contract_period.contract_period_type_id) : ''
                    if(data.data.contract_period.expiration_date){
						this.duration.final_date = {
							selectedDate: data.data.contract_period.expiration_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.duration.months = data.data.contract_period.months ? data.data.contract_period.months : ''
                    this.duration.contract_observations = data.data.contract_period.observations ? data.data.contract_period.observations : ''

                    // salary
                    this.salary.calcul_type = data.data.contract_salary.contract_salary_calculation_type_id ? this.calcul_types.find(el => el.id == data.data.contract_salary.contract_salary_calculation_type_id) : ''
                    this.salary.base_salary = data.data.contract_salary.base_salary ? data.data.contract_salary.base_salary : ''
                    this.salary.net_salary = data.data.contract_salary.net_salary ? data.data.contract_salary.net_salary : ''
                    this.salary.hourly_rate = data.data.contract_salary.hourly_wage ? data.data.contract_salary.hourly_wage : ''

                    // work_program
                    this.work_program.norm = data.data.contract_work_program.norm
                    this.work_program.norm_type = data.data.contract_work_program.working_norm_type_id ? this.norms_type.find(el => el.id == data.data.contract_work_program.working_norm_type_id) : ''
                    this.work_program.daily_hours = data.data.contract_work_program.hours_per_day ? data.data.contract_work_program.hours_per_day : ''
                    this.work_program.vacation_days = data.data.contract_work_program.annual_leave_days ? data.data.contract_work_program.annual_leave_days : ''

                    // fiscality
                    if(data.data.contract_fiscality.deduction_start_date){
						this.fiscality.deduction_start_date = {
							selectedDate: data.data.contract_fiscality.deduction_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscality.retirement_start_date){
						this.fiscality.retirement_start_date = {
							selectedDate: data.data.contract_fiscality.retirement_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscality.disability_start_date){
						this.fiscality.start_date_of_disability = {
							selectedDate: data.data.contract_fiscality.disability_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscality.disability_end_date){
						this.fiscality.end_date_of_disability = {
							selectedDate: data.data.contract_fiscality.disability_end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscality.invalidity_start_date){
						this.fiscality.start_date_of_invalidity = {
							selectedDate: data.data.contract_fiscality.invalidity_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscality.invalidity_end_date){
						this.fiscality.start_date_of_invalidity = {
							selectedDate: data.data.contract_fiscality.invalidity_end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.fiscality.selectedIncomeType = data.data.contract_fiscality.income_type_id ? this.incomeOptions.find(el => el.id == data.data.contract_fiscality.income_type_id) : ''
                    this.fiscality.basic_function = data.data.contract_fiscality.basic_function
                    this.fiscality.selectedWorkCondition = data.data.contract_fiscality.work_condition_type_id ? this.workConditionsOptions.find(el => el.id == data.data.contract_fiscality.work_condition_type_id) : ''
                    this.fiscality.tax_exempt = data.data.contract_fiscality.tax_exemption
                    this.fiscality.retired = data.data.contract_fiscality.retired
                    this.fiscality.selectTaxExptionType = data.data.contract_fiscality.tax_exemption_type_id ? this.taxExemptOptions.find(el => el.id == data.data.contract_fiscality.tax_exemption_type_id) : ''
                    this.fiscality.selectDegreeOfDisability = data.data.contract_fiscality.disability_degree_type_id ? this.disabilityOptions.find(el => el.id == data.data.contract_fiscality.disability_degree_type_id) : ''
                    this.fiscality.selectInvalidityType = data.data.contract_fiscality.invalidity_degree_type_id ? this.invalidityOptions.find(el => el.id == data.data.contract_fiscality.invalidity_degree_type_id) : ''
                    this.fiscality.number_disability_document = data.data.contract_fiscality.disability_document_number ? data.data.contract_fiscality.disability_document_number : ''
                }).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

            async getContractDataCreate() {
                await axios.get(`/contracts/${this.$route.params.contract_id}`, { params: {details: true}}).then(async ({data}) => {
					// personal data
                    this.userData = {avatar: data.data.avatar ? data.data.avatar : '' , cnp: data.data.cnp ? data.data.cnp : '', name: data.data.first_name && data.data.last_name ? data.data.first_name + ' ' + data.data.last_name : ''}

					if(data.data.issue_date){
                        this.personal_data.issue_date = {
                            selectedDate: data.data.issue_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    this.personal_data.cnp = data.data.cnp
                    this.personal_data.selectedCitizenship = this.optionsCountries.find(el => el.name == data.data.country)
                    this.personal_data.selectedCountry = this.optionsCountries.find(el => el.name == data.data.country)
                    if(data.data.country && data.data.country == 'Romania') {
                        if(data.data.county) {
                            this.personal_data.selectedCounty = this.optionsCounties.find(el => el.name.toLowerCase() == data.data.county.toLowerCase())
                            await this.getTowns(this.personal_data.selectedCounty.id)
                        }
                        if(data.data.town) {
                            this.personal_data.selectedTown = this.optionsTowns.find(el => el.name == data.data.town)
                        }
                    } else {
                        this.personal_data.county = data.data.county
                        this.personal_data.town = data.data.town
                    }
                    this.personal_data.street_and_number = data.data.street_and_number
                    this.personal_data.selectDocumentType = data.data.user_document_type_id ? this.optionsDocumentType.find(el => el.id == data.data.user_document_type_id.id) : ''
                    this.personal_data.series = data.data.series
                    this.personal_data.number = data.data.number
                    this.personal_data.issued_by = data.data.issued_by

					// contract details 
                    if(data.data.contract_details) {
                        this.contract_details.selectedBank =  data.data.contract_details.bank ? this.optionsBank.find(el => el.id == data.data.contract_details.bank.id) : '';
                        
                        this.contract_details.iban_code = data.data.contract_details.iban_code ? data.data.contract_details.iban_code : ''
                        this.contract_details.selectedHealthInsurance = data.data.contract_details.national_health_insurance_agency ? this.optionsHealthInsurance.find(el => el.id == data.data.contract_details.national_health_insurance_agency.id) : '';
                        this.contract_details.selectedCodeCor = this.optionsCodes.find(el => el.code == data.data.contract_details.code_cor);
                    }
                    // duration
                    this.duration.selectedContractType = data.data.contract_periods.period_type ? this.contract_types_period.find(el => el.id == data.data.contract_periods.period_type.id) : ''
                    if(data.data.contract_periods.expiration_date){
						this.duration.final_date = {
							selectedDate: data.data.contract_periods.expiration_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.duration.months = data.data.contract_periods.months ? data.data.contract_periods.months : ''
                    this.duration.contract_observations = data.data.contract_periods.observations ? data.data.contract_periods.observations : ''

                    // salary
                    this.salary.calcul_type = data.data.contract_salaries.calculation_type ? this.calcul_types.find(el => el.id == data.data.contract_salaries.calculation_type.id) : ''
                    this.salary.base_salary = data.data.contract_salaries.base_salary ? data.data.contract_salaries.base_salary : ''
                    this.salary.net_salary = data.data.contract_salaries.net_salary ? data.data.contract_salaries.net_salary : ''
                    this.salary.hourly_rate = data.data.contract_salaries.hourly_wage ? data.data.contract_salaries.hourly_wage : ''

                    // work_program
                    this.work_program.norm = data.data.contract_work_programs.norm
                    this.work_program.norm_type = data.data.contract_work_programs.working_norm_type ? this.norms_type.find(el => el.id == data.data.contract_work_programs.working_norm_type.id) : ''
                    this.work_program.daily_hours = data.data.contract_work_programs.hours_per_day ? data.data.contract_work_programs.hours_per_day : ''
                    this.work_program.vacation_days = data.data.contract_work_programs.annual_leave_days ? data.data.contract_work_programs.annual_leave_days : ''

                    // fiscality
                    if(data.data.contract_fiscalities.deduction_start_date){
						this.fiscality.deduction_start_date = {
							selectedDate: data.data.contract_fiscalities.deduction_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscalities.retirement_start_date){
						this.fiscality.retirement_start_date = {
							selectedDate: data.data.contract_fiscalities.retirement_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscalities.disability_start_date){
						this.fiscality.start_date_of_disability = {
							selectedDate: data.data.contract_fiscalities.disability_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscalities.disability_end_date){
						this.fiscality.end_date_of_disability = {
							selectedDate: data.data.contract_fiscalities.disability_end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscalities.invalidity_start_date){
						this.fiscality.start_date_of_invalidity = {
							selectedDate: data.data.contract_fiscalities.invalidity_start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.contract_fiscalities.invalidity_end_date){
						this.fiscality.start_date_of_invalidity = {
							selectedDate: data.data.contract_fiscalities.invalidity_end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.fiscality.selectedIncomeType = data.data.contract_fiscalities.income_type ? this.incomeOptions.find(el => el.id == data.data.contract_fiscalities.income_type.id) : ''
                    this.fiscality.basic_function = data.data.contract_fiscalities.basic_function
                    this.fiscality.selectedWorkCondition = data.data.contract_fiscalities.work_condition_type ? this.workConditionsOptions.find(el => el.id == data.data.contract_fiscalities.work_condition_type.id) : ''
                    this.fiscality.tax_exempt = data.data.contract_fiscalities.tax_exemption
                    this.fiscality.retired = data.data.contract_fiscalities.retired
                    this.fiscality.selectTaxExptionType = data.data.contract_fiscalities.tax_exemption_type ? this.taxExemptOptions.find(el => el.id == data.data.contract_fiscalities.tax_exemption_type.id) : ''
                    this.fiscality.selectDegreeOfDisability = data.data.contract_fiscalities.disability_degree_type ? this.disabilityOptions.find(el => el.id == data.data.contract_fiscalities.disability_degree_type.id) : ''
                    this.fiscality.selectInvalidityType = data.data.contract_fiscalities.invalidity_degree_type ? this.invalidityOptions.find(el => el.id == data.data.contract_fiscalities.invalidity_degree_type.id) : ''
                    this.fiscality.number_disability_document = data.data.contract_fiscalities.disability_document_number ? data.data.contract_fiscalities.disability_document_number : ''
                }).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

			saveAction(type) {
                var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
                    let objData = {contract_periods:{}, contract_details: {}, contract_salaries: {}, contract_work_programs: {}, contract_fiscalities: {}}
                    if(!this.initial_contract) {
                        // Modification
                        if(Object.keys(this.modification.document_date).length){
                            objData.document_date = this.modification.document_date.selectedDate
                        } else {
                            objData.document_date = ''
                        }
                        objData.active_from = this.modification.active_from ? this.modification.active_from.date : ''
                        objData.contract_modification_type_id = this.modification.selectedModificationContract ? this.modification.selectedModificationContract.id : ''
                        objData.document_number = this.modification.document_number ? this.modification.document_number : ''
                        objData.contract_modification_reason_id = this.modification.selectedContractModificationReason ? this.modification.selectedContractModificationReason.id : ''
                        objData.observations = this.modification.observations ? this.modification.observations : ''
                    }
                    objData.initial_contract = this.initial_contract

					// personal data
                    objData.issue_date = Object.keys(this.personal_data.issue_date).length ? this.personal_data.issue_date.selectedDate : ''
		
					objData.cnp = this.personal_data.cnp
					objData.citizenship = this.personal_data.selectedCitizenship ? this.personal_data.selectedCitizenship.name: ''
					objData.country = this.personal_data.selectedCountry ? this.personal_data.selectedCountry.name: ''
					if(this.personal_data.selectedCountry && this.personal_data.selectedCountry.name == 'Romania') {
						if(this.personal_data.selectedCounty) {
							objData.county = this.personal_data.selectedCounty ? this.personal_data.selectedCounty.name: ''
						}
						if(this.personal_data.selectedTown) {
							objData.town = this.personal_data.selectedTown ? this.personal_data.selectedTown.name: ''
						}
					} else {
						objData.county = this.personal_data.county
						objData.town = this.personal_data.town
					}
					objData.street_and_number = this.personal_data.street_and_number
					objData.user_document_type_id = this.personal_data.selectDocumentType ? this.personal_data.selectDocumentType.id: ''
					objData.series = this.personal_data.series
					objData.number = this.personal_data.number
					objData.issued_by = this.personal_data.issued_by

					// Contract details
					objData.contract_details.bank_id = this.contract_details.selectedBank ? this.contract_details.selectedBank.id : ''
					objData.contract_details.iban_code = this.contract_details.iban_code
					objData.contract_details.national_health_insurance_agency_id = this.contract_details.selectedHealthInsurance ? this.contract_details.selectedHealthInsurance.id : ''
					objData.contract_details.code_cor = this.contract_details.selectedCodeCor ? this.contract_details.selectedCodeCor.code : ''

                    // duration
                    objData.contract_periods.expiration_date = Object.keys(this.duration.final_date).length ? this.duration.final_date.selectedDate : ''

                    objData.contract_periods.contract_period_type_id = this.duration.selectedContractType ? this.duration.selectedContractType.id : '' 
                    objData.contract_periods.months = this.duration.months
                    objData.contract_periods.observations = this.duration.contract_observations 
                    
                    // salary
                    objData.contract_salaries.contract_salary_calculation_type_id = this.salary.calcul_type ? this.salary.calcul_type.id : ''
                    objData.contract_salaries.base_salary = this.salary.base_salary
                    objData.contract_salaries.net_salary = this.salary.net_salary
                    objData.contract_salaries.hourly_wage = this.salary.hourly_rate
                    //  salary: { advance: ''}

                    // work_program
                    objData.contract_work_programs.norm = this.work_program.norm
                    objData.contract_work_programs.working_norm_type_id = this.work_program.norm_type ? this.work_program.norm_type.id : ''
                    objData.contract_work_programs.hours_per_day = this.work_program.daily_hours
                    objData.contract_work_programs.annual_leave_days = this.work_program.vacation_days

                    //fiscality
                    objData.contract_fiscalities.deduction_start_date = Object.keys(this.fiscality.deduction_start_date).length ? this.fiscality.deduction_start_date.selectedDate : ''
                    objData.contract_fiscalities.retirement_start_date = Object.keys(this.fiscality.retirement_start_date).length ? this.fiscality.retirement_start_date.selectedDate : ''
                    objData.contract_fiscalities.disability_start_date = Object.keys(this.fiscality.start_date_of_disability).length ? this.fiscality.start_date_of_disability.selectedDate : ''
                    objData.contract_fiscalities.disability_end_date = Object.keys(this.fiscality.end_date_of_disability).length ? this.fiscality.end_date_of_disability.selectedDate : ''
                    objData.contract_fiscalities.invalidity_start_date = Object.keys(this.fiscality.start_date_of_invalidity).length ? this.fiscality.start_date_of_invalidity.selectedDate : ''
                    objData.contract_fiscalities.invalidity_end_date = Object.keys(this.fiscality.end_date_of_invalidity).length ? this.fiscality.end_date_of_invalidity.selectedDate : ''

                    objData.contract_fiscalities.income_type_id = this.fiscality.selectedIncomeType ? this.fiscality.selectedIncomeType.id : ''
                    objData.contract_fiscalities.work_condition_type_id = this.fiscality.selectedWorkCondition ? this.fiscality.selectedWorkCondition.id : ''
                    objData.contract_fiscalities.basic_function = this.fiscality.basic_function
                    objData.contract_fiscalities.tax_exemption = this.fiscality.tax_exempt
                    objData.contract_fiscalities.retired = this.fiscality.retired
                    objData.contract_fiscalities.tax_exemption_type_id = this.fiscality.selectTaxExptionType ? this.fiscality.selectTaxExptionType.id : ''
                    objData.contract_fiscalities.disability_degree_type_id = this.fiscality.selectDegreeOfDisability ? this.fiscality.selectDegreeOfDisability.id : ''
                    objData.contract_fiscalities.disability_document_number = this.fiscality.number_disability_document
                    objData.contract_fiscalities.invalidity_degree_type_id = this.fiscality.selectInvalidityType ? this.fiscality.selectInvalidityType.id : ''

                    if(type == 'create'){
						this.createContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

                }else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$route.params.contract_id}/contract-modifications/store`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
                        this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'history'} })
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.email) {
								this.error_email = true;
							} else if(error.response.data.errors.business_email) {
								this.error_business_email = true;
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
                            if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
            updateContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/contract-modifications/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
                        this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'history'} })
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.email) {
								this.error_email = true;
							} else if(error.response.data.errors.business_email) {
								this.error_business_email = true;
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
                            if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
		}
	}
</script>

<style lang="scss">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>