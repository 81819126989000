<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.salary.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.salary.calcul-type') }}</label>
				</div>
				<div class="input-box bg-gray">
					<div class="icon-left"><icon-calculator /></div>
					<!-- <div class="icon-right" v-if="salary.calcul_type != ''" @click.stop="salary.calcul_type = ''"><icon-close class="icon-clear" /></div> -->
					<multiselect 
						v-model="salary.calcul_type"
						v-bind:class="{populate: salary.calcul_type != ''}"
						class="select-tags-tbf"
						:options="calculTypes"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						disabled
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.salary.calcul-type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.salary.base_salary') }}</label>
                    <div v-if="validator.base_salary.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.base_salary.$error}">
					<div class="icon-left"><icon-salary /></div>
					<div class="icon-right" v-if="salary.base_salary != ''" @click.stop="salary.base_salary = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.salary.base_salary-ph')" class="input-text" v-model="salary.base_salary">
				</div>
			</div>
            <!-- <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.salary.net_salary') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-mobile /></div>
					<div class="icon-right" v-if="salary.net_salary != ''" @click.stop="salary.net_salary = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.salary.net_salary-ph')" class="input-text" v-model="salary.net_salary">
				</div>
			</div> -->
            <!-- <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.salary.hourly_rate') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-salary /></div>
					<div class="icon-right" v-if="salary.hourly_rate != ''" @click.stop="salary.hourly_rate = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.salary.hourly_rate-ph')" class="input-text" v-model="salary.hourly_rate">
				</div>
			</div> -->
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.salary.advance') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-salary /></div>
					<div class="icon-right" v-if="salary.advance != ''" @click.stop="salary.advance = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.salary.advance-ph')" class="input-text" v-model="salary.advance">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconCalculator from '../../../../Icons/Calculator'
	import IconSalary from '../../../../Icons/Salary'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconCalculator,
			IconSalary,
			IconCalendar,
			IconClose
		},
		props: {
			salary: Object,
			validator: Object,
			calculTypes: Array
		},
		data() {
			return {
				loaded: false,
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		}
	}
</script>