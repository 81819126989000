<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.modification.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.active_from') }}*</label>
                    <div v-if="validator.active_from.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box months" v-bind:class="{has_error: validator.active_from.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="modification.active_from != ''" @click.stop="modification.active_from = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="modification.active_from"
						v-bind:class="{populate: modification.active_from != ''}"
						class="select-tags-tbf months"
						:options="months"
						:allow-empty="true"
						:show-labels="false"
						track-by="date" 
						label="name"
						@open="openAtCurentDate"
						>
						<template slot="singleLabel" slot-scope="props">
							<img v-if="props.option.img" class="option__image contracts" :src="props.option.img">
							<span class="option__desc">
								<span class="option__title">{{ props.option.name }}</span>
							</span>
						</template>
						<template slot="option" slot-scope="props">
							<img v-if="props.option.img" class="option__image contracts" :src="props.option.img">
							<div class="option__desc">
								<span class="option__title">{{ props.option.name }}</span>
							</div>
						</template>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.modification.active_from-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							<template slot="placeholder" slot-scope="props">
								<span class="text">
									{{ $t('salary.modification.active_from-ph') }}
								</span>
							</template>
							<template slot="noResult">{{ $t('create-user.no-results') }}</template>
							<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
						</multiselect>
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.contract-type') }}*</label>
                    <div v-if="validator.selectedModificationContract.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectedModificationContract.$error}">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="modification.selectedModificationContract != ''" @click.stop="modification.selectedModificationContract = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="modification.selectedModificationContract"
						v-bind:class="{populate: modification.selectedModificationContract != ''}"
						class="select-tags-tbf"
						:options="contractTypes"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.modification.contract-type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.document_date') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="Object.keys(modification.document_date).length" @click.stop="modification.document_date = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="modification.document_date"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(modification.document_date).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.modification.document_date-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<!-- <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.active_from') }}</label>
					<div v-if="validator.active_from.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.active_from.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(modification.active_from).length" @click.stop="modification.active_from = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="modification.active_from"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(modification.active_from).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.modification.active_from-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div> -->
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.document_number') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="modification.document_number != ''" @click.stop="modification.document_number = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.modification.document_number-ph')" class="input-text" v-model="modification.document_number">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.contract-reason') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="modification.selectedContractModificationReason != ''" @click.stop="modification.selectedContractModificationReason = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="modification.selectedContractModificationReason"
						v-bind:class="{populate: modification.selectedContractModificationReason != ''}"
						class="select-tags-tbf"
						:options="contractReason"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.modification.contract-reason-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.modification.observations') }}</label>
				</div>
				<div class="input-box bg-white">
					<textarea rows="3" :placeholder="$t('salary.modification.observations-ph')" v-model="modification.observations"></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconMobile from '../../../../Icons/Mobile'
	import IconContract from '../../../../Icons/Contract'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconMobile,
			IconContract,
			IconCalendar,
			IconClose
		},
		props: {
			modification: Object,
			validator: Object,
			contractTypes: Array,
            contractReason: Array,
			months: Array
		},
		data() {
			return {
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				isOpen: false
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
		methods: {
			openAtCurentDate() {
				if(document.querySelector(".select-tags-tbf.months > div.multiselect__content-wrapper > ul > li > .multiselect__option--selected")) {
					setTimeout(() => {
						var elmnt = document.querySelector(".select-tags-tbf.months > div.multiselect__content-wrapper > ul > li > .multiselect__option--selected")
						elmnt.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
					}, 20);
				} else {
					const date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, "0") + '-01'.toString()
					const index = this.months.findIndex(x => x.date == date);
					setTimeout(() => {
						this.scrollIfNeeded(document.getElementsByClassName("multiselect__element")[index], document.getElementsByClassName('multiselect__content-wrapper')[0]);
					}, 20);
				}
			},
			scrollIfNeeded(element, container) {
				if (element.offsetTop < container.scrollTop) {
					container.scrollTop = element.offsetTop;
				} else {
					const offsetBottom = element.offsetTop + element.offsetHeight;
					const scrollBottom = container.scrollTop + container.offsetHeight;
					if (offsetBottom > scrollBottom) {
						container.scrollTop = offsetBottom - container.offsetHeight + 250;
					}
				}
			}
		},
	}
</script>