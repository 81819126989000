<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.fiscality.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.type_income') }}*</label>
                    <div v-if="validator.selectedIncomeType.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box bg-gray" v-bind:class="{has_error: validator.selectedIncomeType.$error}">
					<div class="icon-left"><icon-contract /></div>
					<!-- <div class="icon-right" v-if="fiscality.selectedIncomeType != ''" @click.stop="fiscality.selectedIncomeType = ''"><icon-close class="icon-clear" /></div> -->
					<multiselect 
						v-model="fiscality.selectedIncomeType"
						v-bind:class="{populate: fiscality.selectedIncomeType != ''}"
						class="select-tags-tbf"
						:options="incomeOptions"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						disabled
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.fiscality.type_income-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.working_conditions') }}*</label>
                    <div v-if="validator.selectedWorkCondition.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectedWorkCondition.$error}">
					<div class="icon-left"><icon-work /></div>
					<!-- <div class="icon-right" v-if="fiscality.selectedWorkCondition != ''" @click.stop="fiscality.selectedWorkCondition = ''"><icon-close class="icon-clear" /></div> -->
					<multiselect 
						v-model="fiscality.selectedWorkCondition"
						v-bind:class="{populate: fiscality.selectedWorkCondition != ''}"
						class="select-tags-tbf"
						:options="workConditionsOptions"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.fiscality.working_conditions-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                <div class="label-header">
                    <label class="label">{{ $t('salary.fiscality.basic_function') }}*</label>
                    <div v-if="validator.basic_function.$error" class="error-msg">{{ $t('validator.required') }}</div>
                </div>
                <div class="input-box bor-0" v-bind:class="{has_error: validator.basic_function.$error}">
                    <div class="checkbox-list">
                        <div class="checkbox-item m-4" @click="fiscality.basic_function = 0" v-bind:class="{active: fiscality.basic_function === 0}">
                            <div class="name">{{ $t('create-user.no') }}</div>
                        </div>
                        <div class="checkbox-item m-4" @click="fiscality.basic_function = 1" v-bind:class="{active: fiscality.basic_function === 1}">
                            <div class="name">{{ $t('create-user.yes') }}</div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.deduction_start_date') }}</label>
					<div v-if="validator.deduction_start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.deduction_start_date.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(fiscality.deduction_start_date).length" @click.stop="fiscality.deduction_start_date = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="fiscality.deduction_start_date"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(fiscality.deduction_start_date).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.fiscality.deduction_start_date-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div class="delimeter-form"></div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                <div class="label-header">
                    <label class="label">{{ $t('salary.fiscality.tax_exempt') }}*</label>
                    <div v-if="validator.tax_exempt.$error" class="error-msg">{{ $t('validator.required') }}</div>
                </div>
                <div class="input-box bor-0" v-bind:class="{has_error: validator.tax_exempt.$error}">
                    <div class="checkbox-list">
                        <div class="checkbox-item m-4" @click="fiscality.tax_exempt = 0" v-bind:class="{active: fiscality.tax_exempt === 0}">
                            <div class="name">{{ $t('create-user.no') }}</div>
                        </div>
                        <div class="checkbox-item m-4" @click="fiscality.tax_exempt = 1" v-bind:class="{active: fiscality.tax_exempt === 1}">
                            <div class="name">{{ $t('create-user.yes') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="fiscality.tax_exempt" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.type_taxt_exempt') }}*</label>
					<div v-if="validator.selectTaxExptionType.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectTaxExptionType.$error}">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="fiscality.selectTaxExptionType != ''" @click.stop="fiscality.selectTaxExptionType = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="fiscality.selectTaxExptionType"
						v-bind:class="{populate: fiscality.selectTaxExptionType != ''}"
						class="select-tags-tbf"
						:options="taxExemptOptions"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.fiscality.type_taxt_exempt-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
			<div class="delimeter-form"></div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                <div class="label-header">
                    <label class="label">{{ $t('salary.fiscality.retired') }}*</label>
                    <div v-if="validator.retired.$error" class="error-msg">{{ $t('validator.required') }}</div>
                </div>
                <div class="input-box bor-0" v-bind:class="{has_error: validator.retired.$error}">
                    <div class="checkbox-list">
                        <div class="checkbox-item m-4" @click="fiscality.retired = 0" v-bind:class="{active: fiscality.retired === 0}">
                            <div class="name">{{ $t('create-user.no') }}</div>
                        </div>
                        <div class="checkbox-item m-4" @click="fiscality.retired = 1" v-bind:class="{active: fiscality.retired === 1}">
                            <div class="name">{{ $t('create-user.yes') }}</div>
                        </div>
                    </div>
                </div>
            </div>
			<div v-if="fiscality.retired" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.retirement_start_date') }}*</label>
					<div v-if="validator.retirement_start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.retirement_start_date.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="Object.keys(fiscality.retirement_start_date).length" @click.stop="fiscality.retirement_start_date = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="fiscality.retirement_start_date"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(fiscality.retirement_start_date).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.fiscality.retirement_start_date-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div class="delimeter-form"></div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.degree_of_disability') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-handicap /></div>
					<div class="icon-right" v-if="fiscality.selectDegreeOfDisability != ''" @click.stop="fiscality.selectDegreeOfDisability = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="fiscality.selectDegreeOfDisability"
						v-bind:class="{populate: fiscality.selectDegreeOfDisability != ''}"
						class="select-tags-tbf"
						:options="disabilityOptions"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.fiscality.degree_of_disability-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div v-if="fiscality.selectDegreeOfDisability != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.start_date_of_disability') }}</label>
					<!-- <div v-if="validator.start_date_of_disability.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="Object.keys(fiscality.start_date_of_disability).length" @click.stop="fiscality.start_date_of_disability = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="fiscality.start_date_of_disability"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(fiscality.start_date_of_disability).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.fiscality.start_date_of_disability-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
            <div v-if="fiscality.selectDegreeOfDisability != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.end_date_of_disability') }}</label>
					<!-- <div v-if="validator.end_date_of_disability.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(fiscality.end_date_of_disability).length" @click.stop="fiscality.end_date_of_disability = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="fiscality.end_date_of_disability"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(fiscality.end_date_of_disability).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.fiscality.end_date_of_disability-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div v-if="fiscality.selectDegreeOfDisability != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.number_disability_document') }}</label>
					<!-- <div v-if="validator.number_disability_document.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-handicap /></div>
					<div class="icon-right" v-if="fiscality.number_disability_document != ''" @click.stop="fiscality.number_disability_document = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.fiscality.number_disability_document-ph')" class="input-text" v-model="fiscality.number_disability_document">
				</div>
			</div>
			<div class="delimeter-form"></div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.invalidity_type') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-handicap /></div>
					<div class="icon-right" v-if="fiscality.selectInvalidityType != ''" @click.stop="fiscality.selectInvalidityType = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="fiscality.selectInvalidityType"
						v-bind:class="{populate: fiscality.selectInvalidityType != ''}"
						class="select-tags-tbf"
						:options="invalidityOptions"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.fiscality.invalidity_type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div v-if="fiscality.selectInvalidityType != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.start_date_of_invalidity') }}</label>
					<!-- <div v-if="validator.start_date_of_invalidity.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(fiscality.start_date_of_invalidity).length" @click.stop="fiscality.start_date_of_invalidity = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="fiscality.start_date_of_invalidity"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(fiscality.start_date_of_invalidity).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.fiscality.start_date_of_invalidity-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
            <div v-if="fiscality.selectInvalidityType != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.fiscality.end_date_of_invalidity') }}</label>
					<!-- <div v-if="validator.end_date_of_invalidity.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(fiscality.end_date_of_invalidity).length" @click.stop="fiscality.end_date_of_invalidity = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="fiscality.end_date_of_invalidity"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(fiscality.end_date_of_invalidity).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.fiscality.end_date_of_invalidity-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconMobile from '../../../../Icons/Mobile'
	import IconWork from '../../../../Icons/Work'
	import IconHandicap from '../../../../Icons/Handicap'
	import IconContract from '../../../../Icons/Contract'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconMobile,
			IconWork,
			IconHandicap,
			IconContract,
			IconCalendar,
			IconClose
		},
		props: {
			fiscality: Object,
			validator: Object,
			incomeOptions: Array,
            workConditionsOptions: Array,
            taxExemptOptions: Array,
            disabilityOptions: Array,
            invalidityOptions:Array
		},
		data() {
			return {
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		}
	}
</script>